import Typography from "antd/es/typography/Typography";
import PALETTE from "./palette";
import { BackgroundJpg } from "assets";

export default {
  token: {
    colorBgContainer: PALETTE.white,
    colorPrimary: PALETTE.text.primary,
    // its for custom component, see example in RoutesSideMenus
    Menu: {
      colorBgContainer: PALETTE.white,
    },
    Footer: {
      colorBgContainer: PALETTE.white,
    },
    Header: {
      colorBgContainer: `url(${BackgroundJpg})`,
    },
    Palette: PALETTE,
  },
  components: {
    Button: {
      colorPrimary: PALETTE.primary,
      colorSecondary: PALETTE.secondary,
      colorTextPrimary: PALETTE.text.tertiary,
    },
    Typography: {
      colorText: PALETTE.text.primary,
      fontSizeHeading2: "1.8rem",
      fontSizeHeading3: "1.6rem",
      fontSizeHeading4: "1.4rem",
      fontSizeHeading5: "1.2rem",
    },
  },
};
